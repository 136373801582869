<template>
  <page-list
    show-ext-tags
    :list="list"
    :columns="columns"
    :group-actions="groupActions"
    :single-actions="singleActions"
    :showSearchbox="showSearchbox"
    :showGroupActions="showGroupActions"
    :ext-tag-params="{ service: 'identity' }" />
   <!--
    :export-data-options="exportDataOptions" -->
</template>

<script>
import ListMixin from '@/mixins/list'
import WindowsMixin from '@/mixins/windows'
import { getEnabledFilter, getProjectDomainFilter, getDescriptionFilter } from '@/utils/common/tableFilter'
import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import GlobalSearchMixin from '@/mixins/globalSearch'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'CustomerList',
  mixins: [WindowsMixin, ListMixin, GlobalSearchMixin, ColumnsMixin, SingleActionsMixin],
  props: {
    id: String,
    getParams: {
      type: Object,
    },
    isAllowCreate: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'customers',
        apiVersion: 'v1',
        getParams: this.getParam,
        sortKeys: ['name', 'project_domain'],
        filterOptions: {
          // id: {
          //   label: this.$t('table.title.id'),
          // },
          name: {
            label: '客户ID',
            filter: true,
            formatter: val => {
              return `name.contains("${val}")`
            },
          },
          description: getDescriptionFilter(),
          displayname: {
            label: this.$t('scope.text_245'),
            filter: true,
            formatter: val => {
              return `displayname.contains("${val}")`
            },
          },
          enabled: getEnabledFilter(),
          business_type: {
            label: '合作方式',
            dropdown: true,
            items: [
              { label: '定向', key: 1 },
              { label: '竞标', key: 2 },
            ],
          },
          second_price: {
            label: '二次定价',
            dropdown: true,
            items: [
              { label: '是', key: true },
              { label: '否', key: false },
            ],
          },
          // allow_web_console: {
          //   label: this.$t('system.text_475'),
          //   dropdown: true,
          //   items: [
          //     { label: this.$t('system.text_476'), key: true },
          //     { label: this.$t('system.text_477'), key: false },
          //   ],
          // },
          project_domain: getProjectDomainFilter(),
          // created_at: getCreatedAtFilter(),
          // idp_id: {
          //   label: this.$t('dictionary.identity_provider'),
          // },
        },
        responseData: this.responseData,
        // hiddenColumns: ['created_at'],
      }),
      exportDataOptions: {
        items: [
          { label: 'ID', key: 'id' },
          { label: this.$t('system.text_101'), key: 'name' },
          { label: this.$t('scope.text_245'), key: 'displayname' },
          { label: this.$t('dictionary.domain'), key: 'project_domain' },
          { label: this.$t('system.text_163'), key: 'enabled' },
          { label: this.$t('system.text_475'), key: 'allow_web_console' },
          { label: this.$t('common.createdAt'), key: 'created_at' },
        ],
      },
      userTotal: 0,
    }
  },
  computed: {
    groupActions () {
      const actions = [
        {
          label: this.$t('system.text_128'),
          permission: 'customers_create',
          action: () => {
            this.$router.push('/customer/create')
          },
          meta: () => {
            const ret = {
              buttonType: 'primary',
            }
            return ret
          },
        },
        // {
        //   label: this.$t('system.text_478'),
        //   permission: 'users_create',
        //   action: () => {
        //     this.createDialog('UserImprotDialog', {
        //       onManager: this.onManager,
        //       refresh: this.refresh,
        //     })
        //   },
        // },
        {
          label: this.$t('system.text_166'),
          actions: () => {
            return [
              {
                label: this.$t('system.text_479'),
                permission: 'customers_update',
                action: () => {
                  this.createDialog('UserUpdateDialog', {
                    data: this.list.selectedItems,
                    columns: this.columns,
                    onManager: this.onManager,
                  })
                },
              },
              ...getEnabledSwitchActions(this, undefined, ['customers_perform_enable', 'customers_perform_disable'], {
                actions: [
                  () => {
                    this.onManager('batchPerformAction', {
                      ids: this.list.selectedItems.map(({ id }) => id),
                      managerArgs: {
                        action: 'enable',
                      },
                    })
                  },
                  () => {
                    this.onManager('batchPerformAction', {
                      ids: this.list.selectedItems.map(({ id }) => id),
                      managerArgs: {
                        action: 'disable',
                      },
                    })
                  },
                ],
              }),
              {
                label: this.$t('system.text_129'),
                permission: 'customers_delete',
                action: () => {
                  if (this.list.selectedItems.some(item => item.enabled)) {
                    this.$message.error('存在已启用的客户，请禁用后删除')
                    return
                  }
                  this.createDialog('DeleteResDialog', {
                    vm: this,
                    data: this.list.selectedItems,
                    columns: this.columns,
                    title: '删除客户',
                    name: '客户',
                    onManager: this.onManager,
                  })
                },
                meta: () => {
                  return {
                    validate: !this.list.selectedItems.some(item => item.enabled),
                  }
                },
              },
            ]
          },
          meta: () => ({
            validate: !!this.list.selectedItems.length,
          }),
        },
      ]
      if (!this.isAllowCreate) {
        actions.shift()
        actions.shift()
      }
      return actions
    },
  },
  async created () {
    this.initSidePageTab('user-detail')
    this.list.fetchData()
    // 新增完成后根据参数跳转可用资源页面
    const { tab, id } = this.$route.query
    if (tab && id) {
      const userResponse = await new this.$Manager('customers', 'v1')
        .get({ id: id })
      const user = userResponse.data
      switch (tab) {
        case 'available-resources':
          this.handleOpenSidepage(user, tab)
          break

        default:
          break
      }
    }
  },
  methods: {
    getParam () {
      const ret = {
        ...this.getParams,
        details: true,
        user_type: 'customer',
      }
      return ret
    },
    handleOpenSidepage (row, tab) {
      this.sidePageTriggerHandle(this, 'CustomerSidePage', {
        id: row.id,
        resource: 'customers',
        apiVersion: 'v1',
        getParams: this.getParam,
      }, {
        list: this.list,
        tab,
      })
    },
  },
}
</script>
